import "./styles.scss"

import React from "react"

const Content = ({ data }) => {
  return (
    <section className="companies-content">
      <div className="container-fluid">
        <div className="companies-content__table">
          <div className="companies-content__table-header">
            <div>LP</div>
            <div>Nazwa spółki</div>
            <div>Nazwa inwestycji</div>
            <div>NIP</div>
          </div>
          {data?.acfCompanies?.companiesContent.map((item, index) => (
            <div className="companies-content__table-item" key={index}>
              <div>{index + 1}.</div>
              <div>
                <strong>
                  <span>{index + 1}. </span> {item?.name}
                </strong>
              </div>
              <div className={!item?.investment ? "empty" : ""}>
                <p>
                  <span>Inwestycja:</span> {item?.investment}
                </p>
              </div>
              <div>
                <span>NIP:</span> {item?.nip}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Content
