import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content } from "page_components/companies"

const Companies = ({ data, location }) => {
  const title = "Spółki Trust Investment"

  return (
    <Layout
      location={location}
      seo={{
        title: title,
      }}
    >
      <Breadcrumbs
        parent={{ label: "Deweloper", url: "/deweloper/" }}
        title={title}
      />
      <PageHeader title={title} />
      <Content data={data?.allWpPage?.nodes[0]} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDoyMzUzMw==" } }) {
      nodes {
        acfCompanies {
          companiesContent {
            name
            investment
            nip
          }
        }
      }
    }
  }
`

export default Companies
